import React from "react";
import SectionTitle from "./sectiontitle";
import { StaticQuery, graphql } from "gatsby";
import "../style/contact.less";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitDisabled: false
        };

        this.textAreaInput = this.textAreaInput.bind(this);
        
        this.showContactForm = true;

        if (this.props.contact.api_url === "") {
            this.showContactForm = false;
        }
    }

    textAreaInput(event) {
        event.target.style.height = "auto";
        event.target.style.height = event.target.scrollHeight + "px";
    }

   

    componentDidMount() {
        /*if (this.showContactForm) {
            let color = window
                .getComputedStyle(this.btn, null)
                .getPropertyValue("color");
            this.btn.querySelector("path").setAttribute("fill", color);
        }

        let li = this.contactArea.querySelectorAll(".item");

        li.forEach(function(e, i) {
            let p = e.querySelector("path");
            if (p)
                p.setAttribute(
                    "fill",
                    window.getComputedStyle(e, null).getPropertyValue("color")
                );
        });*/
    }

    render() {
        return (
            <section id="contact" className="container">
                <div className="section-title">
                    <SectionTitle title="KONTAKT" />
                </div>
                <div
                    className={"row" + (this.showContactForm ? "" : " no-form")}
                    ref={c => (this.contactArea = c)}
                >
                    {this.showContactForm && (
                        <div className="col s12 m6 card" >
                           <img
                                        src="/images/Visitenkarte.png"
                                        height="400"
                                        alt="Visitenkarte"
                                    ></img>
                        </div>
                    )
                    }
                    <div
                        className={
                            this.showContactForm
                                ? "col s12 m6 details"
                                : "col s12 details"
                        }
                    >
                        {this.props.contact.description && (
                            <p className="text-secondary">
                                {this.props.contact.description}
                            </p>
                        )}
                        <ul>
                            {this.props.contact.mail && (
                                <li className="text-secondary item">
                                    <a
                                        href={
                                            "mailto:" + this.props.contact.mail
                                        }
                                    >
                                        <span className="icon">
                                            <img src="/images/Mail.png" alt="Mail" height="25" />
                                        </span>
                                        {this.props.contact.mail}
                                    </a>
                                </li>
                            )}
                            {this.props.contact.phone && (
                                <li className="text-secondary item">
                                    
                                    <a href={"tel:" + this.props.contact.phone}>
                                        <span className="icon">
                                            <img src="/images/Phone.png" alt="Telefon" height="25" />
                                        </span>
                                        {this.props.contact.phone}
                                    </a>
                                </li>
                            )}
                            {this.props.contact.address && (
                                <li
                                    className="text-secondary item"
                                    style={{ whiteSpace: "pre" }}
                                >
                                    <span className="icon">
                                        <img src="/images/Home.png" alt="Telefon" height="25" />
                                    </span>
                                    {this.props.contact.address}
                                </li>
                            )}
                            <li className="text-secondary item">
                                <a href="https://www.facebook.com/Art-from-the-heart-Elke-von-Brevern-112165503635279/" target="_new">
                                <span className="icon">
                                    <img src="/images/Facebook.png" alt="Facebook" height="25" />
                                </span>
                                Facebook
                                </a>
                            </li>
                            <li className="text-secondary item">
                                <a href="http://www.instagram.com/evb_artfromtheheart" target="_new">
                                <span className="icon">
                                    <img src="/images/Instagram.png" alt="Instagram" height="25" />
                                </span>
                                Instagram
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        contact {
                            api_url
                            description
                            mail
                            phone
                            address
                        }
                        social {
                            name
                            url
                            icon
                        }
                    }
                }
            }
        `}
        render={data => <Contact contact={data.site.siteMetadata.contact} />}
    />
);
